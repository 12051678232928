import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import router from './router'
import store from './store'
import root from './root'
import CraList from '@/components/cra-list'
import BillList from '@/components/bill-list'
import NameAndMail from '@/components/name-and-mail'
import Snack from '@/components/snack'
import numeral from 'numeral'
import GlobalEvents from 'vue-global-events'
import Meta from 'vue-meta'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';
import axios  from 'axios'
import Cookies from 'js-cookie'

import '@/assets/index.css';

/* dotenv variable expansion */
var dotenv = require('dotenv')
var dotenvExpand = require('dotenv-expand')
var myEnv = dotenv.config()
dotenvExpand(myEnv)
/* dotenv variable expansion */

window.__VUE_ASSET_PATH = process.env.ASSET_PATH

Vue.use(Meta)
Vue.use(ElementUI)
Vue.config.productionTip = false

moment.locale('fr')

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€'
  }
})

numeral.locale('fr')

Vue.component('name-and-mail', NameAndMail)
Vue.component('cra-list', CraList)
Vue.component('bill-list', BillList)
Vue.component('snack', Snack)
Vue.component('GlobalEvents', GlobalEvents)

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    const loc = location.hostname.split('.')
    loc.shift()
    loc.unshift('')
    Cookies.remove('sessionKey', {
      domain: loc.join('.'),
    })
    store.commit('setSessionKey', null)
    store.commit('setUser', null)
    location.href = process.env.VUE_APP_SITE_BASE
  }
  throw error;
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(root)
}).$mount('#app')
