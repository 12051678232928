<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Votre identité
    </v-card-title>

    <v-card-text>
      <v-flex>
        <v-text-field
          label="Veuillez renseigner votre nom et votre prénom"
          placeholder="Pierre Durand"
          v-model="i_name"
          :rules="[rules.required, rules.count]"
          outlined
        />
      </v-flex>
      <v-flex>
        <p>Renseigner votre adresse email vous permet de recevoir automatiquement le rapport signé en PDF.</p>
        <v-text-field
          label="Adresse email du valideur"
          placeholder="example@societe-du-client.com"
          v-model="i_mail"
          :rules="[rules.email]"
          outlined
        />
      </v-flex>
      <v-flex xs3>
        <el-button
          type="primary"
          @click="save"
        >
          Enregistrer
        </el-button>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    mail: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      i_name: this.name,
      i_mail: this.mail,
      rules: {
        required: value => !!value || 'Vos nom et prénom sont requis',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || 'Cet email est invalide'
        },
        count: v => !v || v.length > 7 || 'Entrez vos noms et prénoms'
      }
    }
  },
  methods: {
    save () {
      this.$emit('save', {
        mail: this.i_mail ? this.i_mail : null,
        name: this.i_name ? this.i_name : null
      })
    }
  }
}
</script>

<style>

</style>
