import Vue from 'vue'
import Vuex from 'vuex'
import { apiGet } from '@/assets/js/helpers/apiQuery'
import Cookies from 'js-cookie'

Vue.use(Vuex)

const pending = {
  userQuery: null,
  companyQuery: null
}

function capitalizeFLetter (word) {
  return word[0].toUpperCase() + word.slice(1)
}

const ensure = async function (state, commit, what, quer, force) {
  const query = what + 'Query'
  if ((state[what] === null && !pending[query]) || force) {
    pending[query] = apiGet('/' + quer)
    commit('set' + capitalizeFLetter(what), await pending[query])
  } else if (pending[query]) {
    await pending[query]
  }
}

export default new Vuex.Store({
  state: {
    tempMail: null,
    sessionKey: null,
    user: null,
    printKey: null,
    company: null,
    snack: {
      message: null,
      visible: false,
      color: null
    }
  },
  getters: {
    isFree (state) {
      return state.company && state.company.plan === 1
    },
    isFacture (state) {
      return state.company && state.company.plan === 2
    },
    isMicro (state) {
      return state.company && state.company.plan === 3
    },
    isCompany (state) {
      return state.company && state.company.plan === 4
    },
    tempMail (state) {
      return state.tempMail
    }
  },
  mutations: {
    setCompany (state, val) {
      state.company = val
      pending.companyQuery = null
    },
    setUser (state, val) {
      state.user = val
      pending.userQuery = null
    },
    setPrintKey (state, val) {
      state.printKey = val
    },
    setSessionKey (state, val) {
      state.sessionKey = val
    },
    showSnack (state, { message, color }) {
      state.snack.message = message
      state.snack.color = color
      state.snack.visible = true
    },
    hideSnack (state) {
      state.snack.visible = false
    },
    setTempMail (state, val) {
      state.tempMail = val
    }
  },
  actions: {
    async ensureUser ({ commit, state }, force) {
      try {
        const sessionFromStorage = Cookies.get('sessionKey')
        if (sessionFromStorage) {
          commit('setSessionKey', sessionFromStorage)
          await ensure(state, commit, 'user', 'me', force)
        }
      } catch (ex) {
        console.error(ex)
      }
    },
    async ensureCompany ({ commit, state }, force) {
      await ensure(state, commit, 'company', 'company', force)
    }
  }
})
