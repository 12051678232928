import axios from 'axios'
import store from '@/store'
import _merge from 'lodash/merge'

const baseApi = process.env.VUE_APP_API_BASE
const domain = location.host

const apiGet = async function (url, config) {
  let print
  if (store.state.printKey) {
    print = {
      params: {
        printKey: store.state.printKey
      }
    }
  } else {
    print = {}
  }
  return (await axios.get(`${baseApi}${url}`, _merge({
    params: {
      auth_token: store.state.sessionKey,
      domain
    }
  }, print, (config || {})))).data
}

const apiDelete = async function (url, config) {
  return (await axios.delete(`${baseApi}${url}`, _merge({
    params: {
      auth_token: store.state.sessionKey,
      domain
    }
  }, (config || {})))).data
}

const apiPost = async function (url, data, config) {
  return (await axios.post(`${baseApi}${url}`, data || {}, _merge({
    params: {
      auth_token: store.state.sessionKey,
      domain
    }
  }, (config || {})))).data
}

const apiPut = async function (url, data, config) {
  return (await axios.put(`${baseApi}${url}`, data || {}, _merge({
    params: {
      auth_token: store.state.sessionKey,
      domain
    }
  }, (config || {})))).data
}

const apiPatch = async function (url, data, config) {
  return (await axios.patch(`${baseApi}${url}`, data || {}, _merge({
    params: {
      auth_token: store.state.sessionKey,
      domain
    }
  }, (config || {})))).data
}

export {
  apiGet,
  apiDelete,
  apiPost,
  apiPatch,
  apiPut
}
