<template>
  <v-flex xs12 class="bill-list">
    <v-data-table
      v-if="list"
      :items="list"
      :headers="filteredHeaders"
      sort-by="id"
      sort-desc
      no-data-text="Aucune facture n'a encore été ajoutée"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ getMonth(props.item) }}</td>
          <td v-if="!provider">{{ props.item.client && props.item.client.name }}</td>
          <td v-if="!provider"><span :class="payedColor(props.item.payed, true)">{{ props.item.dueDate.format('DD/MM/YYYY') }}</span></td>
          <td>{{ props.item.billNumber }}</td>
          <td>{{ props.item.nb }}</td>
          <td style="text-align: right">{{ withVAT(props.item) }} €</td>
          <td class="justify-center px-0">
            <v-icon
              @click="downloadBill(props.item)"
              :disabled="props.item.pendingGeneration"
            >
              mdi-cloud-download
            </v-icon>
          </td>
          <td v-if="!provider" class="justify-center">
            <template v-if="!props.item.paperSent && !props.item.emailSent">
              <v-menu
                open-on-hover
                top
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="payedColor(false)"
                    v-on="on"
                    :disabled="props.item.pendingGeneration"
                  >
                    mdi-send
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <el-button
                        size="small"
                        type="primary"
                        @click="sendMail(props.item)"
                        :disabled="props.item.client && !props.item.client.hasBillingMail"
                      >
                        Envoyer le mail
                      </el-button>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <el-button
                        size="small"
                        type="primary"
                        @click="markPaperSent(props.item)"
                      >
                        Marquer comme envoyé
                      </el-button>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-send</v-icon>
              </template>
              <span>Envoyé</span>
            </v-tooltip>
          </td>
          <td class="justify-center px-0" v-if="$store.getters.isMicro || $store.getters.isCompany">
            <v-menu
              :value="dp.id === props.item.id"
              @input="dp.id = $event ? props.item.i : null"
              :close-on-content-click="false"
              :position-x="dp.x"
              :position-y="dp.y"
            >
              <v-date-picker
                v-if="dp.id"
                @input="sendPayed(props.item, $event)"
              />
            </v-menu>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="payedColor(props.item.payed)"
                  :class="{
                    'cannot-be-marked-as-payed': !canBeMarkedAsPayed(props.item)
                  }"
                  @click="togglePayed(props.item, $event)"
                >
                  mdi-currency-eur
                </v-icon>
              </template>
              <span>{{ payedTitle(props.item) }}</span>
            </v-tooltip>
          </td>
          <td class="justify-center px-0">
            <v-icon
              v-if="props.item.isLast || provider"
              @click="deleteItem(props.item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import _map from 'lodash/map'
import _clone from 'lodash/clone'
import _filter from 'lodash/filter'
import moment from 'moment'
import numeral from 'numeral'
import axios from 'axios'
import { apiDelete, apiPost } from '@/assets/js/helpers/apiQuery'

export default {
  data () {
    return {
      dp: {
        id: null,
        x: 0,
        y: 0
      }
    }
  },
  props: {
    bills: {
      type: Array,
      default: () => []
    },
    provider: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    withVAT (bill) {
      const val = Math.round((bill.total + bill.tva) * 100) / 100
      return numeral(val).format('0,0.00')
    },
    getMonth (bill) {
      return moment(bill.date).format('MMMM YYYY')
    },
    async deleteItem (bill) {
      if (window.confirm('Etes-vous bien sur de vouloir supprimer cette facture?')) {
        await apiDelete(`/bills/${bill.id}`)
        this.$emit('refresh')
      }
    },
    async downloadBill (bill) {
      const response = await axios({
        url: `${process.env.VUE_APP_API_BASE}/bills/${bill.id}/pdf?auth_token=${this.$store.state.sessionKey}&domain=${location.host}`,
        method: 'GET',
        responseType: 'blob'
      })
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', bill.billNumber || moment(bill.date).format('LL') + '.pdf')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
    dueDate (bill) {
      return moment(bill.date).endOf('month').add(parseInt(bill.paymentDelay), 'days')
    },
    async sendMail (bill) {
      try {
        await apiPost(`/bills/${bill.id}/mails`)
        this.$emit('refresh')
      } catch (error) {
        this.$store.commit('showSnack', { message: 'échec lors de l\'envoie du mail', color: 'error' })
      }
    },
    async markPaperSent (bill) {
      await apiPost(`/bills/${bill.id}/paper-sent-mark`)
      this.$emit('refresh')
    },
    payedColor (param, invert = false) {
      const multi = ['grey' + (invert ? '--text' : '')]

      if ((param || invert) && !(param && invert)) {
        multi.push((invert ? 'text--' : '') + 'darken-3')
      } else {
        multi.push((invert ? 'text--' : '') + 'lighten-2')
      }
      return multi.join(' ')
    },
    payedTitle (item) {
      return item.payed ? 'payé le ' + moment(item.payed).format('DD/MM/YYYY') : 'non payé'
    },
    canBeMarkedAsPayed (item) {
      return !item.payed
    },
    async togglePayed (item, e) {
      if (this.canBeMarkedAsPayed(item)) {
        this.dp.id = item.id
        this.dp.x = e.clientX
        this.dp.y = e.clientY
      } else {
        if (window.confirm('Etes-vous bien sur de vouloir supprimer cette facture?')) {
          await apiDelete(`/bills/${item.id}/payment-mark`)
          this.$emit('refresh')
        }
      }
    },
    async sendPayed (item, date) {
      this.dp.id = null
      await apiPost(`/bills/${item.id}/payment-mark`, { date })
      this.$emit('refresh')
    }
  },
  computed: {
    list () {
      return _map(this.bills, b => {
        const item = _clone(b)
        item.dueDate = this.dueDate(item)
        item.dueDateSortable = item.dueDate.format('YYYMMDD')
        return item
      })
    },
    headers () {
      return [
        { sortable: false, visible: true, text: 'Mois', align: 'left' },
        { sortable: false, visible: !this.provider, text: 'Client' },
        { sortable: this.$vuetify.breakpoint.smAndUp, visible: !this.provider, text: 'Due date', value: 'dueDate' },
        { sortable: this.$vuetify.breakpoint.smAndUp, visible: true, text: 'N°', value: 'billNumber' },
        { sortable: false, visible: true, text: 'Nb jours', value: 'description' },
        { sortable: false, visible: true, text: 'Montant', align: 'right' },
        { sortable: false, visible: true },
        { sortable: false, visible: !this.provider },
        { sortable: false, visible: this.$store.getters.isMicro || this.$store.getters.isCompany, align: 'left', value: 'payed' },
        { sortable: false, visible: true }
      ]
    },
    filteredHeaders () {
      return _filter(this.headers, header => header.visible)
    }
  }
}
</script>

<style lang="scss">
  .bill-list {
    .theme--light {
      &.validated {
        color: green;
      }
    }
  }
</style>
