import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Cookies from 'js-cookie'

const Cra = () => import(/* webpackChunkName: "CraPage" */'@/site/app/cra')
const ValidateCra = () => import(/* webpackChunkName: "CraPage" */'@/public/cra/validate')
const Contracts = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract')
const ListContracts = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract/list')
const CreateContract = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract/create')
const CreateClient = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract/client/create')
const EditMission = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract/edit')
const EditClient = () => import(/* webpackChunkName: "ContractPage" */'@/site/app/contract/client/edit')
const User = () => import(/* webpackChunkName: "UserPage" */'@/site/app/user')
const CreateUser = () => import(/* webpackChunkName: "UsersPage" */'@/site/app/user/create')
const EditUser = () => import(/* webpackChunkName: "UsersPage" */'@/site/app/user/edit')
const ListUsers = () => import(/* webpackChunkName: "UsersPage" */'@/site/app/user/list')
const Resource = () => import(/* webpackChunkName: "ResourcesPage" */'@/site/app/resource')
const CreateResource = () => import(/* webpackChunkName: "ResourcesPage" */'@/site/app/resource/create')
const ListResources = () => import(/* webpackChunkName: "ResourcesPage" */'@/site/app/resource/list')
const ListBdcs = () => import(/* webpackChunkName: "BdcPage" */'@/site/app/bdc/list')
const Bdc = () => import(/* webpackChunkName: "BdcPage" */'@/site/app/bdc')
const CreateBdc = () => import(/* webpackChunkName: "BdcPage" */'@/site/app/bdc/create')
const Billing = () => import(/* webpackChunkName: "BillingPage" */'@/site/app/billing')
const BillingClientCreate = () => import(/* webpackChunkName: "BillingPage" */'@/site/app/billing/client-create')
const BillingProviderCreate = () => import(/* webpackChunkName: "BillingPage" */'@/site/app/billing/provider-create')
const Site = () => import(/* webpackChunkName: "SitePage" */'@/site')
const App = () => import(/* webpackChunkName: "AppPage" */'@/site/app')
const InfoSociete = () => import(/* webpackChunkName: "Info" */'@/site/app/info-societe')
const InfoCompte = () => import(/* webpackChunkName: "Info" */'@/site/app/info-compte')
const InfoContact = () => import(/* webpackChunkName: "Info" */'@/site/app/info-contact')
const Abonnement = () => import(/* webpackChunkName: "Info" */'@/site/app/info-abo')
const Declarations = () => import(/* webpackChunkName: "Declarations" */'@/site/app/declarations')
const Payments = () => import(/* webpackChunkName: "Admin" */'@/site/app/payments')
const Companies = () => import(/* webpackChunkName: "CompaniesPage" */'@/site/app/company')
const ListCompanies = () => import(/* webpackChunkName: "CompaniesPage" */'@/site/app/company/list')
const EditCompany = () => import(/* webpackChunkName: "CompaniesPage" */'@/site/app/company/edit')
const Prospect = () => import(/* webpackChunkName: "Admin" */'@/site/app/prospect')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */'@/site/app/dashboard')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: Site,
    children: [{
      path: '',
      component: App,
      children: [{
        path: '',
        component: Dashboard
      }, {
        path: '/declarations',
        component: Declarations
      }, {
        path: '/payments',
        component: Payments
      }, {
        path: '/companies',
        component: Companies,
        children: [{
          path: '',
          name: 'listCompanies',
          component: ListCompanies
        }, {
          path: ':companyId',
          redirect: (to) => ({ path: `${to.params.companyId}/info` })
        }, {
          path: ':companyId/info',
          component: EditCompany,
          name: 'companyDetailInfo',
          props: (route) => ({ companyId: route.params.companyId })
        }, {
          path: ':companyId/perso',
          component: EditCompany,
          name: 'companyDetailPerso',
          props: (route) => ({ companyId: route.params.companyId })
        }, {
          path: ':companyId/admin',
          component: EditCompany,
          name: 'companyDetailAdmin',
          props: (route) => ({ companyId: route.params.companyId })
        }, ]
      }, {
        path: '/contract',
        component: Contracts,
        children: [{
          path: '',
          name: 'listContracts',
          component: ListContracts
        }, {
          path: 'client/create',
          name: 'createClient',
          component: CreateClient
        }, {
          path: 'client/edit/:clientId',
          name: 'editClient',
          component: EditClient,
          props: (route) => ({ clientId: parseInt(route.params.clientId) })
        }, {
          path: 'create',
          name: 'createContract',
          component: CreateContract,
          props: (route) => ({ clientId: parseInt(route.params.clientId) })
        }, {
          path: 'edit/:missionId',
          name: 'editMission',
          component: EditMission,
          props: (route) => ({ missionId: parseInt(route.params.missionId) })
        }]
      }, {
        path: '/user',
        component: User,
        children: [{
          path: 'list',
          name: 'listUsers',
          default: true,
          component: ListUsers
        }, {
          path: 'create',
          component: CreateUser
        }, {
          path: 'edit/:userId',
          name: 'editUser',
          component: EditUser,
          props: (route) => ({ userId: parseInt(route.params.userId) })
        }]
      }, {
        path: '/resource',
        component: Resource,
        children: [{
          path: 'list',
          name: 'listResources',
          default: true,
          component: ListResources
        }, {
          path: 'create',
          name: 'createResource',
          component: CreateResource
        }]
      }, {
        path: '/bdc',
        component: Bdc,
        children: [{
          path: 'list',
          name: 'listBdc',
          default: true,
          component: ListBdcs
        }, {
          path: 'create',
          component: CreateBdc
        }]
      }, {
        path: '/cra',
        component: Cra
      }, {
        path: '/billing',
        redirect: '/billing/client'
      }, {
        path: '/billing/client',
        name: 'billingClient',
        component: Billing
      }, {
        path: '/billing/provider',
        name: 'billingProvider',
        component: Billing
      }, {
        path: '/billing/client/create',
        name: 'billingClientCreate',
        component: BillingClientCreate
      }, {
        path: '/billing/provider/create',
        name: 'billingProviderCreate',
        component: BillingProviderCreate
      }, {
        path: '/info/mon-compte',
        component: InfoCompte
      }, {
        path: '/info/ma-societe',
        redirect: '/info/ma-societe/info'
      }, {
        path: '/info/ma-societe/info',
        name: 'editCompanyInfo',
        component: InfoSociete
      }, {
        path: '/info/ma-societe/perso',
        name: 'editCompanyPerso',
        component: InfoSociete
      }, {
        path: '/info/ma-societe/admin',
        name: 'editCompanyAdmin',
        component: InfoSociete
      }, {
        path: '/info/abonnement',
        component: Abonnement
      }, {
        path: '/info/contact',
        component: InfoContact
      }, {
        path: '/prospects',
        component: Prospect
      }]
    }]
  }, {
    path: '/cra/validate/:craUuid',
    component: ValidateCra,
    props: true
  }]
})

router.beforeEach((to, from, next) => {
  if (to.query && to.query.sessionKey) {
    let query = Object.assign({}, to.query)
    const loc = location.hostname.split('.')
    loc.shift()
    loc.unshift('')
    Cookies.set('sessionKey', query.sessionKey, {
      domain: loc.join('.'),
      expires: 100,
      secure: true
    })
    delete to.query.sessionKey
    router.replace(to)
  }
  if (to.query && to.query.printKey) {
    let query = Object.assign({}, to.query)
    store.commit('setPrintKey', query.printKey)
    delete to.query.printKey
    router.replace(to)
  }

  next()
})

export default router
